<template>
  <div>
    <el-form :inline="true" :model="queryFormModel" class="demo-form-inline">
      <el-form-item label="名称">
        <el-input v-model="queryFormModel.keyword" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="应用">
        <el-select v-model="queryFormModel.appId" placeholder="请选择" clearable>
          <el-option v-for="item in appList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryList">查 询</el-button>
        <el-button @click="showAddDialog">新 增</el-button>
      </el-form-item>
    </el-form>

    <el-pagination 
      style="margin-bottom: 5px; float: right;"
      background 
      layout="prev, pager, next" 
      :current-page.sync="page" 
      :page-size.sync="rows" 
      :total="tableTotal" 
      @current-change="currentPageChanged">
    </el-pagination>

    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="id" label="ID" width="200"></el-table-column>
      <el-table-column prop="appName" label="所属应用" width="200"></el-table-column>
      <el-table-column prop="name" label="名称"> </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="200">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" circle @click="showEditDialog(scope.row)"></el-button>
          <!-- <el-button type="danger" icon="el-icon-delete" circle @click="del(scope.row)"></el-button> -->
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="应用" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-form :model="entity" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="ID" prop="id">
          <el-input v-model="entity.id" disabled></el-input>
        </el-form-item>
        <el-form-item label="所属应用" prop="appId" required>
          <el-select v-model="entity.appId" placeholder="请选择" :disabled="mode == '2'">
            <el-option v-for="item in appList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称" prop="name" required>
          <el-input v-model="entity.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import httpClient from '../httpClient.js'
export default {
  name: "MerchantList",
  data() {
    return {
      page: 1,
      rows: 10,
      tableTotal: 0,
      tableData: [],
      dialogVisible: false,
      entity: {},
      rules: {},
      appList: [],
      queryFormModel: {}
    };
  },
  methods: {
    queryList() {
      let data = {
        page: this.page,
        rows: this.rows,
        params: this.queryFormModel
      }
      httpClient.post("/api/merchant/query", data).then((response) => {
        this.tableTotal = response.data.data.total
        this.tableData = response.data.data.list
      })
    },
    queryAllAppList() {
      let params = {};
      httpClient.post("/api/app/query", params).then((response) => {
        this.appList = response.data.data.list
      })
    },
    currentPageChanged() {
      this.queryList()
    },
    showAddDialog() {
      this.entity = {}
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
    },
    doSave() {
      httpClient.request({
        url: '/api/merchant',
        method: this.entity.id ? 'PUT' : 'POST',
        data: this.entity
      }).then((response) => {
        this.entity = response.data.data
        this.dialogVisible = false
        this.queryList()
      })
    },
    showEditDialog(row) {
      httpClient.get(`/api/merchant/${row.id}`).then((response) => {
        this.entity = response.data.data
        this.dialogVisible = true
      })
    },
    del(row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        httpClient.delete(`/api/merchant/${row.id}`).then(() => {
          this.$notify({ title: '成功', message: '删除成功', type: 'success' })
          this.queryList()
        })
      }).catch(() => {
        this.$message({type: 'info', message: '已取消删除'})
      })
    }
  },
  mounted() {
    this.queryList()
    this.queryAllAppList()
  },
};
</script>
<template>
  <div>
    <el-form :inline="true" :model="queryFormModel" class="demo-form-inline">
      <el-form-item label="名称">
        <el-input v-model="queryFormModel.keyword" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="商户">
        <el-select v-model="queryFormModel.merchantId" placeholder="请选择" clearable filterable @change="merchantQueryChanged">
          <el-option v-for="item in merchantList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="代理">
          <el-select v-model="queryFormModel.proxyId" placeholder="请选择" clearable filterable>
            <el-option v-for="item in proxyList4Query" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryList">查 询</el-button>
        <el-button @click="showAddDialog">新 增</el-button>
      </el-form-item>
    </el-form>

    <el-pagination 
      style="margin-bottom: 5px; float: right;"
      background 
      layout="prev, pager, next" 
      :current-page.sync="page" 
      :page-size.sync="rows" 
      :total="tableTotal" 
      @current-change="currentPageChanged">
    </el-pagination>

    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="id" label="ID" width="200"></el-table-column>
      <el-table-column prop="merchantName" label="所属商户" width="200"></el-table-column>
      <el-table-column prop="proxyName" label="所属代理" width="200"></el-table-column>
      <el-table-column prop="code" label="编码" width="80"></el-table-column>
      <el-table-column prop="name" label="名称"></el-table-column>
      <el-table-column prop="type" label="类型" :formatter="scaleTypeFormatter"></el-table-column>
      <el-table-column prop="ip" label="IP" width="130"></el-table-column>
      <el-table-column prop="port" label="端口" width="80"></el-table-column>
      <el-table-column prop="enable" label="启用状态" width="100">
        <template slot-scope="scope">
            <el-switch v-model="scope.row.enable" active-color="#13ce66" inactive-color="#ff4949" active-value="1" inactive-value="0" disabled></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="180">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" circle @click="showEditDialog(scope.row)"></el-button>
          <el-button type="danger" icon="el-icon-delete" circle @click="del(scope.row)"></el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="设备" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-form :model="entity" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="所属商户" prop="merchantId" required>
          <el-select v-model="entity.merchantId" placeholder="请选择" :disabled="mode == '2'" @change="merchantChanged" filterable>
            <el-option v-for="item in merchantList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属代理" prop="proxyId" required>
          <el-select v-model="entity.proxyId" placeholder="请选择" :disabled="!entity.merchantId || mode == '2'" filterable>
            <el-option v-for="item in proxyList4Form" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="编码" prop="code" required>
          <el-input v-model="entity.code"></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="name" required>
          <el-input v-model="entity.name"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type" required>
          <el-select v-model="entity.type" placeholder="请选择">
            <el-option v-for="item in scaleTypeList" :key="item.code" :label="item.name" :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="IP" prop="ip" required>
          <el-input v-model="entity.ip"></el-input>
        </el-form-item>
        <el-form-item label="端口" prop="port" required>
          <el-input v-model="entity.port"></el-input>
        </el-form-item>
        <el-form-item label="启用状态" prop="enable">
          <el-switch v-model="entity.enable" active-color="#13ce66" inactive-color="#ff4949" active-value="1" inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import httpClient from "../httpClient.js";
export default {
  name: "ScaleList",
  data() {
    return {
      mode: null, // 1-新增,2-修改
      page: 1,
      rows: 10,
      tableTotal: 0,
      tableData: [],
      dialogVisible: false,
      entity: {},
      rules: {},
      merchantList: [],
      proxyList4Query: [],
      proxyList4Form: [],
      queryFormModel: {},
      scaleTypeList: [],
      scaleTypeMap: {}
    };
  },
  methods: {
    queryList() {
      let data = {
        page: this.page,
        rows: this.rows,
        params: this.queryFormModel
      }
      httpClient.post("/api/scale/query", data).then((response) => {
        this.tableTotal = response.data.data.total
        this.tableData = response.data.data.list
      })
    },
    currentPageChanged() {
      this.queryList()
    },
    queryAllMerchantList() {
      let params = { rows: 1000 }
      httpClient.post("/api/merchant/query", params).then((response) => {
        this.merchantList = response.data.data.list
      })
    },
    queryAllScaleType() {
      httpClient.get("/api/scale/type").then((response) => {
        this.scaleTypeList = response.data.data
        this.scaleTypeList.forEach(item => {
          this.scaleTypeMap[item.code] = item.name
        })
      })
    },
    merchantQueryChanged(merchantId) {
      let params = {
        rows: 1000,
        params: {
          merchantId: merchantId
        }
      }
      httpClient.post("/api/proxy/query", params).then((response) => {
        this.proxyList4Query = response.data.data.list
      })
    },
    merchantChanged(merchantId) {
      let params = {
        rows: 1000,
        params: {
          merchantId: merchantId
        }
      }
      httpClient.post("/api/proxy/query", params).then((response) => {
        this.proxyList4Form = response.data.data.list
      })
    },
    showAddDialog() {
      this.entity = {}
      this.mode = '1'
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
    },
    doSave() {
      httpClient.request({
        url: '/api/scale',
        method: this.entity.id ? 'PUT' : 'POST',
        data: this.entity
      }).then((response) => {
        this.entity = response.data.data
        this.dialogVisible = false
        this.queryList()
      })
    },
    showEditDialog(row) {
      httpClient.get(`/api/scale/${row.id}`).then((response) => {
        this.entity = response.data.data
        this.mode = '2'
        this.dialogVisible = true
        // 查询商户下的代理列表，用于回显
        let params = {
          merchantId: this.entity.merchantId
        }
        httpClient.post("/api/proxy/query", { params }).then((response) => {
          this.proxyList4Form = response.data.data.list
        })
      })
    },
    del(row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        httpClient.delete(`/api/scale/${row.id}`).then(() => {
          this.$notify({ title: '成功', message: '删除成功', type: 'success' })
          this.queryList()
        })
      }).catch(() => {
        this.$message({type: 'info', message: '已取消删除'})
      })
    },
    scaleTypeFormatter(row) {
        return this.scaleTypeMap[row.type]
    }
  },
  mounted() {
    this.queryList()
    this.queryAllMerchantList()
    this.queryAllScaleType()
    this.merchantQueryChanged()
  },
};
</script>
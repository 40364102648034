<style>
.logout-btn {
    position: absolute;
    right: 10px;
    top: 10px;
}
</style>
<template>
  <div>
    <el-container>
      <el-header>
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item index="AppList">应用管理</el-menu-item>
          <el-menu-item index="MerchantList">商户管理</el-menu-item>
          <el-menu-item index="ProxyList">代理管理</el-menu-item>
          <el-menu-item index="ScaleList">设备管理</el-menu-item>
          <el-menu-item index="TaskList">传称任务</el-menu-item>
        </el-menu>
        <el-button class="logout-btn" @click="logout">登 出</el-button>
      </el-header>
      <el-main>
        <component :is="activeIndex"></component>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import AppList from "./AppList.vue"
import MerchantList from "./MerchantList.vue"
import ProxyList from "./ProxyList.vue";
import ScaleList from "./ScaleList.vue";
import TaskList from "./TaskList.vue";

export default {
  name: "MainLayout",
  components: {
    AppList,
    MerchantList,
    ProxyList,
    ScaleList,
    TaskList
  },
  data() {
    return {
      activeIndex: "AppList",
    };
  },
  methods: {
    handleSelect(index) {
        this.activeIndex = index
    },
    logout() {
      localStorage.removeItem('sessionKey')
      this.$router.push({ name: 'login' })
    }
  },
  computed: {
    componentName () {
        return ['AppList','MerchantList','ProxyList','ScaleList','TaskList'][this.activeIndex]
    }
  }
};
</script>
import axios from "axios"
import router from '@/router'
import { Message } from 'element-ui'

axios.defaults.baseURL = process.env.VUE_APP_APIHOST

axios.interceptors.request.use(config => {
    config.headers.SessionKey = localStorage.getItem("sessionKey")
    return config
})

axios.interceptors.response.use(response => {
    if (response.data.code == 401) {
        router.push('/login')
        return
    }
    if (response.data.code == 1) {
        Message.error(response.data.message)
        return Promise.reject(response)
    }
    if (response.data.code == 2) {
        Message.error(response.data.message)
        return Promise.reject(response)
    }
    return response
})

export default axios;
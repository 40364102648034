<template>
<div class="login-container">
  <el-form ref="loginForm" :model="form" :rules="rules" label-position="left" label-width="0px" class="login-form">
    <h3 class="login-title">系统登录</h3>

    <el-form-item prop="username">
      <el-input v-model="form.username" placeholder="用户名" prefix-icon="el-icon-user"></el-input>
    </el-form-item>

    <el-form-item prop="password">
      <el-input v-model="form.password" type="password" placeholder="密码" prefix-icon="el-icon-lock"></el-input>
    </el-form-item>

    <el-form-item>
      <el-alert v-if="error" :title="error" type="error" :closable="false"></el-alert>
      <el-button type="primary" @click="handleSubmit">登录</el-button>
    </el-form-item>
  </el-form>
</div>
</template>

<script>
import httpClient from '../httpClient.js'
export default {
  data() {
    return {
      form: {
        username: '',
        password: '',
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
      },
      error: ''
    };
  },
  methods: {
    handleSubmit() {
      httpClient.post('/login', this.form).then(response => {
        if (response.data.code != 0) {
          this.error = response.data.message
          return
        }else {
          this.error = null
          localStorage.setItem('sessionKey', response.data.data)
          this.$router.push({ name: 'home' })
        }
      })
    },

    validateCredentials(username, password) {
      return username === 'tripod' && password === 'tripod';
    }
  }
};
</script>

<style scoped>/* 添加具体的CSS样式，这里仅给出基本结构 */
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5; /* 示例背景色 */
}

form {
  width: 300px;
  padding: 2em;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

input {
  margin-bottom: 1em;
}

.error-message {
  color: red;
}
</style>
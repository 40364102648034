import Vue from 'vue'
import VueRouter from 'vue-router'

import MainLayout from './components/MainLayout.vue'
import LoginPage from './components/LoginPage.vue'
import SwaggerAnalyzer from './tools/SwaggerAnalyzer.vue'

Vue.use(VueRouter);
const router = new VueRouter({
    mode: 'history',
    routes: [
      { name:'home', path: '/', component: MainLayout },
      { name:'login', path: '/login', component: LoginPage },
      { name:'SwaggerAnalyzer', path: '/api-tool', component: SwaggerAnalyzer }
    ]
  });
  
  router.beforeEach((to, from, next) => {
    const sessionKey = localStorage.getItem('sessionKey')
    console.log('sessionKey:' + sessionKey)
    if (!sessionKey && to.path !== '/login') {
      next({ path: '/login' })
    }else {
      next()
    }
})

export default router